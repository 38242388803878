import styles from "@components/Navigation/Navbar/Navbar.module.scss";
import SubPageLink from "@components/Navigation/Navbar/SubPageLink";
import Link from "next/link";
import { DictionaryType } from "@utils/Localization/getDictionary";
import { pages } from "@components/Navigation/Navbar/Navbar";
import { ReactNode, RefObject, useContext } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { NavContext } from "@components/Navigation/NavProvider";

const NavbarMenu = ({ children, contentRef }: { children?: ReactNode; contentRef: RefObject<HTMLUListElement> }) => {
  const { locale, translate } = useContext(LocalizationContext);
  const { isOpen, isSubpageOpen, currentSubpage, handleOpenSubpage, handleCloseSubpage, getDropdownWidth } =
    useContext(NavContext);

  return (
    <div
      className={isOpen ? styles.contentDropdownOpen : styles.contentDropdown}
      style={{ width: `${getDropdownWidth(contentRef.current)}px` }}
    >
      <ul id="navDropdown" className={styles.links}>
        {pages.map((page, index) => {
          if (page.show) {
            return (
              <li
                key={index}
                className={`${styles.navItem} `}
                onClick={(e) => {
                  if (page.subpages && !isSubpageOpen) {
                    e.preventDefault();
                    handleOpenSubpage(page.name, page.subpages);
                  } else {
                    handleCloseSubpage();
                  }
                }}
              >
                {page.subpages ? (
                  <SubPageLink page={page} currentSubpage={currentSubpage} />
                ) : (
                  <Link href={`/${locale}/${page.link}`} passHref>
                    {translate(page.name as keyof DictionaryType)}
                  </Link>
                )}
              </li>
            );
          }

          return null;
        })}

        {children}
      </ul>
    </div>
  );
};

export default NavbarMenu;
