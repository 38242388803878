export const searchOptions = {
  // This option, if true, will include the score of each result in the returned list
  includeScore: true,

  // This option, if true, will include the matches,
  // or areas where the result matched the search term, in the return list
  includeMatches: true,

  keys: [
    // The 'name' property specifies the path to the property that should be searched in each item, and
    // the 'weight' property specifies how much relevance should be given to matches found in this property.
    // This is a scale of 0 to 1, with 1 being the highest.
    { name: "productNumber", weight: 0.9 },
    { name: "elNumber", weight: 0.9 },
    { name: "productName", weight: 0.8 },
    { name: "productNameShort", weight: 0.7 },
    { name: "product_category.name", weight: 0.7 },
    { name: "gtin", weight: 0.6 },
    { name: "hsCode", weight: 0.5 },
    { name: "description", weight: 0.4 },
    { name: "specification.text", weight: 0.2 }
  ],

  // Determines if the returned list should be sorted by the relevance of the results.
  // If true, the most relevant items will be returned first.
  shouldSort: true,

  // A number between 0 and 1 that determines how close a result
  // should be to the search term to be included in the results.
  // A lower number means a closer match, with 0 being a perfect match, and 1 allowing all items to match.
  threshold: 0.3,

  // Specifies where in the text to start the search. It could be beneficial when the data set is large.
  location: 0,

  // Determines how far from the 'location' to search in the text.
  // Increasing this value allows for search results to be found even if they are far away from the location.
  distance: 500,

  // Specifies the minimum number of characters in the text that constitutes a match.
  // Helps to filter out less relevant results.
  minMatchCharLength: 1,

  // Allows you to specify a richer query, using logical operators like OR and NOT,
  // or to specify an exact match with quotes.
  useExtendedSearch: true
};
