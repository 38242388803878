"use client";

import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

import useWindowDimensions from "@utils/UseWindowDimensions";
import { PageLink, pages, SubpageLink } from "@components/Navigation/Navbar/Navbar";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { usePathname } from "next/navigation";

export interface NavInterface {
  isOpen: boolean;
  isOpening: boolean;
  isClosing: boolean;
  isMobile: boolean;
  isSubpageOpen: boolean;
  subpageLinks: SubpageLink[] | null;
  currentSubpage: string | null;
  currentPage: PageLink | undefined;
  setIsOpen: (isOpen: boolean) => void;
  setIsOpening: (isOpening: boolean) => void;
  setIsClosing: (isClosing: boolean) => void;
  setIsMobile: (isMobile: boolean) => void;
  setIsSubpageOpen: (isSubpageOpen: boolean) => void;
  setSubpageLinks: (subpageLinks: SubpageLink[] | null) => void;
  setCurrentSubpage: (currentSubpage: string | null) => void;
  getContentWidth: (element: HTMLElement | HTMLUListElement | null) => number;
  getDropdownWidth: (element: HTMLElement | HTMLUListElement | null) => number;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
  handleOpenSubpage: (name: string, subpages: SubpageLink[]) => void;
  handleCloseSubpage: () => void;
}

export const NavContext = createContext<NavInterface>({} as NavInterface);

const NavProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const pathname = usePathname();
  const { locale } = useContext(LocalizationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSubpageOpen, setIsSubpageOpen] = useState(false);
  const [subpageLinks, setSubpageLinks] = useState<SubpageLink[] | null>(null);
  const [currentSubpage, setCurrentSubpage] = useState<string | null>(null);

  const currentPage =
    pathname === `/${locale}`
      ? pages[0]
      : pages.find((page) => pathname?.includes(`/${locale}/${page.link}`)) || undefined;
  const { width } = useWindowDimensions();

  // On route change close the menu
  useEffect(() => {
    handleClose();
  }, [pathname]);

  useEffect(() => {
    if (width && width <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  const handleOpen = () => {
    if (isOpening || isClosing) return;

    setIsOpen(true);
    setIsOpening(true);
    setTimeout(() => {
      setIsOpening(false);
    }, 250);
  };

  const handleClose = () => {
    if (isOpening || isClosing) return;
    setIsClosing(true);

    handleCloseSubpage();

    if (!isSubpageOpen) {
      setIsOpen(false);
    } else {
      setTimeout(() => {
        setIsOpen(false);
      }, 250);
    }

    setTimeout(
      () => {
        setIsClosing(false);
      },
      isSubpageOpen ? 500 : 250
    );
  };

  const handleOpenSubpage = (name: string, subpages: SubpageLink[]) => {
    if (isOpening || isClosing) return;

    setSubpageLinks(subpages);
    setCurrentSubpage(name);
    setIsSubpageOpen(true);
  };

  const handleCloseSubpage = () => {
    setIsSubpageOpen(false);
    setSubpageLinks(null);
    setCurrentSubpage(null);
  };

  const handleToggle = () => {
    if (isOpen) {
      setSubpageLinks(null);
      handleClose();
    } else {
      handleOpen();
    }
  };

  const getContentWidth = (element: HTMLElement | HTMLUListElement | null) => {
    // return current width of contentRef
    return element?.getBoundingClientRect().width || 1920;
  };

  const getDropdownWidth = (element: HTMLElement | HTMLUListElement | null) => {
    return width ? (width - getContentWidth(element)) / 2 + 400 : 600;
  };

  return (
    <NavContext.Provider
      value={{
        isOpen,
        isOpening,
        isClosing,
        isMobile,
        isSubpageOpen,
        subpageLinks,
        currentSubpage,
        currentPage,
        setIsOpen,
        setIsOpening,
        setIsClosing,
        setIsMobile,
        setIsSubpageOpen,
        setSubpageLinks,
        setCurrentSubpage,
        getContentWidth,
        getDropdownWidth,
        handleOpen,
        handleClose,
        handleToggle,
        handleOpenSubpage,
        handleCloseSubpage
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export default NavProvider;
