import styles from "./NewsletterFooter.module.scss";
import React, { useContext } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { NewsletterForm } from "@components/Common/NewsletterForm";

const NewsletterFooter = () => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.text}>
          <h6>{dictionary.newsLetterFooterTitle}</h6>
          <p>{dictionary.newsLetterFooterText}</p>
        </div>

        <NewsletterForm fields={{ email: true }} />
      </div>
    </div>
  );
};

export default NewsletterFooter;
