"use client";

import Span from "@components/Common/Text/Span";
import FormatPrice from "@components/Products/Price/FormatPrice";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "@components/Auth/UserProvider";
import { CartContext } from "@utils/Cart/cartProvider";
import { CartItem, CartItemImpl } from "@utils/Cart/CartItem";

import { ProductData } from "@utils/Product/interfaces";

const ProductCardPrice = ({ productData }: { productData: ProductData }) => {
  const { user } = useContext(UserContext);
  const { showPrices, discountCode } = useContext(CartContext);

  const [cartItem, setCartItem] = useState<CartItem | null>(null);

  useEffect(() => {
    const newCartItem = new CartItemImpl({
      product: productData,
      quantity: 1,
      priceLevel: user?.priceLevel,
      discountCode
    });

    setCartItem(newCartItem);
  }, [user, discountCode]);

  if (!cartItem || !cartItem.product) {
    return null;
  }

  if (!showPrices) {
    return null;
  }

  return (
    <>
      <Span size="md" color="color-gray flex w-full">
        <FormatPrice cartItem={cartItem} />
        {/*{user?.priceLevel && <span className="ml-auto !lowercase">{dictionary.exclVat}</span>}*/}
      </Span>
    </>
  );
};

export default ProductCardPrice;
