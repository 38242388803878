export const companyInfo = {
  address: "Mjåvannstoppen 10-12",
  zip: 4628,
  city: "Kristiansand",
  country: "Norway",
  support: {
    email: "support@onepole.no",
    phone: "+47 38 13 49 00"
  },
  links: {
    instagram: "https://www.instagram.com/onepole.no/",
    linkedin: "https://www.linkedin.com/company/onepole/",
    facebook: "https://www.facebook.com/onepole.no/"
  }
} as const;
