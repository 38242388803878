"use client";

import styles from "./SearchInput.module.scss";
import TextInput from "@components/Input/TextInput";
import { useContext, useEffect, useRef, useState } from "react";
import { SearchContext } from "@components/Navigation/Search/SearchProvider";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { MagnifyingGlass, X } from "@phosphor-icons/react";

const SearchInput = ({ className, inline = false }: { className?: string; inline?: boolean }) => {
  const ref = useRef<HTMLInputElement>(null);

  const { dictionary } = useContext(LocalizationContext);
  const { isOpen, setIsOpen, setIsContainerOpen, setQuery } = useContext(SearchContext);

  const searchRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }

    if (isOpen) {
      ref.current?.focus();
    }
  }, [isOpen]);

  const handleChange = (value: string) => {
    setSearch(value);

    if (value !== "") {
      setIsOpen(true);
    }

    setQuery(value);
  };

  const handleClose = () => {
    setSearch("");
    setQuery("");
    setIsContainerOpen(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 600);
  };

  return (
    <div className={`${className} ${inline ? styles.containerInline : styles.container}`} ref={searchRef}>
      <div className={styles.input}>
        <TextInput
          ref={ref}
          name="search"
          onChange={(value) => handleChange(value)}
          value={search}
          placeholder={dictionary.search}
        >
          {isOpen ? (
            <X className="input-icon" size={24} weight="light" onClick={() => handleClose()} />
          ) : (
            <MagnifyingGlass className="input-icon" size={24} weight="light" />
          )}
        </TextInput>
      </div>
    </div>
  );
};

export default SearchInput;
