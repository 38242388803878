"use client";

import styles from "./LocaleSwitch.module.scss";
import React, { useState } from "react";
import { LocaleCode } from "@utils/Country/countryEnums";
import { useCountryContext } from "@utils/Country/useCountryContext";
import { getClientLocale } from "@utils/Localization/getClientLocale";
import Chevron from "@components/Navigation/Navbar/LanguageSwitch/Chevron";
import LocaleCard from "@components/Navigation/Navbar/LanguageSwitch/LocaleCard";

const LocaleSwitch = () => {
  const { countryManager } = useCountryContext();
  const locale = getClientLocale();
  const [show, setShow] = useState(false);
  const country = countryManager.getCountryByLocale(locale as LocaleCode);
  const defaultCountry = countryManager.defaultCountry;
  const dropdownLocales = countryManager.getAllCountriesWithLocale();

  return (
    <div
      onClick={() => {
        setShow(!show);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
      className={styles.localeButton}
    >
      <div className={styles.localeContainer}>
        <LocaleCard isLink={false} country={country || defaultCountry} />

        <Chevron show={show} />
      </div>

      <div className={show ? styles.localeContentOpen : styles.localeContent}>
        <div className={styles.showLocale}>
          {dropdownLocales.map((country) => (
            <LocaleCard key={country.countryCode} country={country} onClick={() => setShow(false)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocaleSwitch;
