"use client";

import React, { createContext, useEffect, useRef, useState } from "react";
import Fuse from "fuse.js";
import { searchOptions } from "@utils/Search/searchOptions";
import { ProductData } from "@utils/Product/interfaces";
import { LocaleCode } from "@utils/Country/countryEnums";
import { getClientLocale } from "@utils/Localization/getClientLocale";
import { createProductQuery, QueryOptions } from "@utils/Product/utils";

export interface SearchInterface {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isContainerOpen: boolean;
  setIsContainerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filteredProducts: ProductData[];
}

export const SearchContext = createContext<SearchInterface>({} as SearchInterface);

const SearchProvider = ({
  children,
  products,
  showAllProducts = false,
  fetchAllProducts = true
}: {
  children: React.ReactNode | React.ReactNode[];
  products?: ProductData[];
  showAllProducts?: boolean;
  fetchAllProducts?: boolean;
}) => {
  const locale = getClientLocale();
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isContainerOpen, setIsContainerOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [productData, setProductData] = useState<ProductData[]>(products || []);
  const [filteredProducts, setFilteredProducts] = useState<ProductData[]>(showAllProducts ? products || [] : []);

  useEffect(() => {
    if (!fetchAllProducts) return;

    fetchProducts().then((products) => {
      setProductData(products);
      setFilteredProducts(showAllProducts ? products : []);
    });
  }, []);

  useEffect(() => {
    if (!productData) return;

    // Clear the previous timeout if it exists
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      updateMappedResults();
    }, 500);

    return () => {
      // Cleanup the timeout on component unmount
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [query, productData]);

  const updateMappedResults = () => {
    if (!productData) return;

    const fuse = new Fuse(productData, searchOptions);
    const results = fuse.search(query);
    const mappedResults = results.map((result) => result.item);

    if (showAllProducts && mappedResults.length === 0) {
      setFilteredProducts(productData);
    } else if (!showAllProducts && mappedResults.length === 0) {
      setFilteredProducts([]);
    } else {
      setFilteredProducts(mappedResults);
    }
  };

  const fetchProducts = async () => {
    //
    const query = createProductQuery(
      {
        locale: locale === LocaleCode.Norway ? LocaleCode.Norway : LocaleCode.UnitedKingdom,
        fields: ["description", "elNumber", "gtin", "hsCode"],
        populate: {
          productImages: {
            formats: {
              small: "*"
            },
            alternativeText: "*",
            name: "*"
          },
          specification: {
            populate: {
              specification: "*"
            }
          },
          product_category: {
            populate: {
              id: "*",
              populate: {
                attributes: {
                  populate: {
                    slug: "*",
                    name: "*"
                  }
                }
              }
            }
          }
        }
      } || ({} as QueryOptions)
    );

    const products = await fetch(`/api/products?${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      next: {
        revalidate: 3600
      }
    });

    return await products.json();
  };

  return (
    <SearchContext.Provider
      value={{ isOpen, setIsOpen, isContainerOpen, setIsContainerOpen, query, setQuery, filteredProducts }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
