import styles from "./SubpageLink.module.scss";
import { PageLink } from "@components/Navigation/Navbar/Navbar";
import { DictionaryType } from "@utils/Localization/getDictionary";
import { useContext } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { CaretRight } from "@phosphor-icons/react";

const SubPageLink = ({ page, currentSubpage }: { page: PageLink; currentSubpage: string | null }) => {
  const { translate } = useContext(LocalizationContext);

  return (
    <a className={styles.container}>
      {translate(page.name as keyof DictionaryType)}{" "}
      <CaretRight
        className={currentSubpage?.toLowerCase() === page.name?.toLowerCase() ? styles.caretLeft : styles.caret}
        weight="thin"
        size={24}
      />
    </a>
  );
};

export default SubPageLink;
