"use client";

import styles from "./HamburgerIcon.module.scss";
import { HamburgerDesign, PageLink } from "@components/Navigation/Navbar/Navbar";

const hamburgerDesignStyleMap: Record<keyof typeof HamburgerDesign, string> = {
  White: styles.hamburgerWhite,
  Black: styles.hamburgerBlack
};

const hamburgerOpenDesignStyleMap: Record<keyof typeof HamburgerDesign, string> = {
  White: styles.hamburgerWhiteOpen,
  Black: styles.hamburgerBlackOpen
};

const getStyleFromMap = <T extends NonNullable<unknown>>(map: Record<keyof T, string>, value?: keyof T) => {
  return value ? map[value] || "" : "";
};

const HamburgerIcon = ({
  isOpen,
  className,
  currentPage,
  handleToggle
}: {
  isOpen: boolean;
  className?: string;
  currentPage?: PageLink;
  handleToggle: () => void;
}) => {
  // return (
  //   <div
  //     className={`${isOpen ? styles.containerOpen : styles.container} ${className}`}
  //     onClick={() => handleToggle && handleToggle()}
  //   >
  //     <span></span>
  //     <span></span>
  //     <span></span>
  //   </div>
  // );

  return (
    <div
      className={`${isOpen ? styles.menuOpen : styles.menu} ${
        isOpen
          ? getStyleFromMap(hamburgerOpenDesignStyleMap, currentPage?.hamburgerDesign || "Black")
          : getStyleFromMap(hamburgerDesignStyleMap, currentPage?.hamburgerDesign || "Black")
      } ${className}`}
      onClick={() => handleToggle && handleToggle()}
    >
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
    </div>
  );
};

export default HamburgerIcon;
