"use client";

import styles from "./Search.module.scss";
import SearchResult from "@components/Navigation/Search/SearchResult";
import SearchProvider from "@components/Navigation/Search/SearchProvider";
import SearchButton from "@components/Navigation/Search/SearchButton";

const Search = () => {
  return (
    <SearchProvider>
      <div className={styles.search}>
        <SearchResult />
        <SearchButton />
      </div>
    </SearchProvider>
  );
};

export default Search;
