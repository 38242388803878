"use client";

import styles from "./CartNavbar.module.scss";

import React, { useContext } from "react";
import Link from "next/link";

import CartQuantity from "@components/Navigation/Navbar/CartQuantity";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { ShoppingCartSimple } from "@phosphor-icons/react";

export const CartNavbar = ({ isOpen }: { isOpen?: boolean }) => {
  const { locale } = useContext(LocalizationContext);
  return (
    <div className={isOpen ? styles.cartOpen : styles.cart}>
      <Link href={`/${locale}/cart`}>
        <ShoppingCartSimple size={24} weight="light" />

        <CartQuantity />
      </Link>
    </div>
  );
};

export default CartNavbar;
