"use client";

import styles from "./LocaleCard.module.scss";
import Link from "next/link";
import React, { useContext } from "react";
import { Country } from "@utils/Country/Country";
import { CartContext } from "@utils/Cart/cartProvider";
import { usePathname } from "next/navigation";

const LocaleCard = ({
  isLink = true,
  country,
  onClick
}: {
  isLink?: boolean;
  country: Country;
  onClick?: () => void;
}) => {
  const { switchLocale } = useContext(CartContext);
  const pathName = usePathname();
  let pathNameWithoutLocale = pathName?.replace(/^\/[a-z]{2}(-[a-z]{2})?/, "");

  if (pathNameWithoutLocale?.includes("articles")) {
    pathNameWithoutLocale = "/articles";
  }

  return (
    <div
      key={country.locale}
      onClick={() => {
        switchLocale(country.locale);
      }}
      className={styles.localeCard}
    >
      {isLink ? (
        <Link
          href={{
            pathname: `/${country.locale}${pathNameWithoutLocale}`
          }}
          className={styles.localeOptions}
          onClick={() => {
            onClick && onClick();
          }}
        >
          <img src={`/images/flags/${country.countryCode}.png`} alt="" />
          <span className={styles.localeCardName}>{country?.countryCode.toUpperCase()}</span>
        </Link>
      ) : (
        <div
          className={styles.localeOptions}
          onClick={() => {
            onClick && onClick();
          }}
        >
          <img src={`/images/flags/${country.countryCode}.png`} alt="" />
          <span className={styles.localeCardName}>{country?.countryCode.toUpperCase()}</span>
        </div>
      )}
    </div>
  );
};

export default LocaleCard;
