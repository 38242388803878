"use client";

import styles from "./CartQuantity.module.scss";
import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "@utils/Cart/cartProvider";

const CartQuantity = () => {
  const { cartItemQuantity } = useContext(CartContext);

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted || cartItemQuantity === 0) {
    return null;
  }

  return <div className={styles.cartQuantity}>{cartItemQuantity}</div>;
};

export default CartQuantity;
