"use client";

import styles from "@components/Navigation/Navbar/Navbar.module.scss";

import UserNavbar from "@components/Navigation/Navbar/UserNavbar";
import CartNavbar from "@components/Navigation/Navbar/CartNavbar";
import LocaleSwitch from "@components/Navigation/Navbar/LanguageSwitch/LocaleSwitch";
import Search from "@components/Navigation/Search/Search";

import { ConfigStage } from "@constants/enums";
import { ProductCategory } from "@utils/Product/interfaces";

import OnepoleLogo from "@components/Icons/OnepoleLogo";
import { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import SubPage from "@components/Navigation/Navbar/SubPage";
import HamburgerIcon from "@components/Navigation/Navbar/HamburgerIcon";
import NavbarMenu from "@components/Navigation/Navbar/NavbarMenu";
import { NavContext } from "@components/Navigation/NavProvider";
import useDetectInput from "@components/Input/DetectInput";

export interface SubpageLink {
  name: string;
  link: string;
}

export enum NavbarDesign {
  Blank = "blank",
  White = "white"
}

export enum HamburgerDesign {
  White = "white",
  Black = "black"
}

export interface PageLink {
  name: string;
  link: string;
  design?: keyof typeof NavbarDesign;
  hamburgerDesign?: keyof typeof HamburgerDesign;
  subpages?: SubpageLink[];
  show?: boolean;
}

export const pages: PageLink[] = [
  {
    name: "home",
    link: "/",
    design: "Blank",
    hamburgerDesign: "White"
  },
  {
    name: "The ONEPOLE",
    link: "the-onepole",
    design: "Blank",
    hamburgerDesign: "White",
    show: true
  },
  {
    name: "products",
    link: "products",
    design: "White",
    hamburgerDesign: "Black",
    show: true,
    subpages: [
      {
        name: "buildCTO",
        link: `configurator/stage/${ConfigStage.type}`
      },
      {
        name: "allProducts",
        link: "products"
      },
      ...Object.values(ProductCategory).map((category) => ({
        name: category,
        link: `products/${category}`
      }))
    ]
  },
  {
    name: "articles",
    link: "articles",
    design: "White",
    hamburgerDesign: "Black",
    show: true
  },
  {
    name: "references",
    link: "references",
    design: "White",
    hamburgerDesign: "Black",
    show: true
  },
  {
    name: "safetySustainability",
    link: "safety-sustainability",
    design: "Blank",
    hamburgerDesign: "White",
    show: true
  },
  {
    name: "faq",
    link: "faq",
    design: "White",
    hamburgerDesign: "Black",
    show: true
  },
  {
    name: "contact",
    link: "contact",
    design: "White",
    hamburgerDesign: "Black",
    show: true
  }
];

const designStyleMap: Record<keyof typeof NavbarDesign, string> = {
  Blank: styles.navBlank,
  White: styles.nav
};

const getStyleFromMap = <T extends NonNullable<unknown>>(map: Record<keyof T, string>, value?: keyof T) => {
  return value ? map[value] || "" : "";
};

const Navbar = () => {
  const { locale } = useContext(LocalizationContext);
  const { isOpen, isMobile, currentPage, handleClose, handleToggle } = useContext(NavContext);
  const { scrollPosition } = useDetectInput();
  const contentRef = useRef<HTMLUListElement>(null);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (scrollPosition.y > 100) {
      setIsHidden(true);
      handleClose();
    } else {
      setIsHidden(false);
    }
  }, [scrollPosition]);

  if (isMobile) {
    return (
      <nav id="nav" className={isOpen ? styles.mobileNavOpen : styles.mobileNav}>
        <div
          id="navBackground"
          className={isOpen ? styles.backgroundOpen : styles.background}
          onClick={() => handleClose()}
          onMouseOver={() => handleClose()}
        />

        <div className={`${styles.content} ${isOpen ? styles.mobileOpenWhite : ""}`}>
          <ul className={styles.contentList}>
            <HamburgerIcon isOpen={isOpen} currentPage={currentPage} handleToggle={() => handleToggle()} />
            <OnepoleLogo locale={locale} className={styles.logo} />

            <div className={styles.mobileIcons}>
              <Search />

              <CartNavbar isOpen={isOpen} />
            </div>
          </ul>
        </div>

        <NavbarMenu contentRef={contentRef}>
          <UserNavbar isMobile={isMobile} />
          <LocaleSwitch />
        </NavbarMenu>

        <SubPage useBackButton />
      </nav>
    );
  }

  return (
    <nav id="nav" className={getStyleFromMap(designStyleMap, currentPage?.design || "White")}>
      <div className={isHidden ? styles.contentHidden : styles.content}>
        <ul ref={contentRef} className={styles.contentList}>
          <HamburgerIcon isOpen={isOpen} currentPage={currentPage} handleToggle={() => handleToggle()} />

          <OnepoleLogo locale={locale} className={styles.logo} svg />

          <div className={styles.mainContent}>
            <Search />
            <UserNavbar />
            <CartNavbar />
            <LocaleSwitch />
          </div>
        </ul>
      </div>

      <div
        id="navBackground"
        className={isOpen ? styles.backgroundOpen : styles.background}
        onClick={() => handleClose()}
        onMouseOver={() => handleClose()}
      />

      <NavbarMenu contentRef={contentRef} />

      <SubPage contentRef={contentRef} />
    </nav>
  );
};

export default Navbar;
