"use client";

import styles from "@components/Navigation/Navbar/UserNavButton.module.scss";
import DropdownLink from "@components/Navigation/Navbar/DropdownBox/DropdownLink";
import { RoleName } from "@utils/User/User";
import { LogoutButton } from "@components/Auth/LogoutButton";
import { useContext } from "react";
import { UserContext } from "@components/Auth/UserProvider";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";

const UserDropdown = ({ isOpen, onLeave }: { isOpen: boolean; onLeave?: () => void }) => {
  const { dictionary, locale } = useContext(LocalizationContext);
  const { user } = useContext(UserContext);

  return (
    <ul
      className={isOpen ? styles.dropDownOpen : styles.dropDown}
      onMouseEnter={(e) => {
        e.stopPropagation();
      }}
      onMouseLeave={(e) => {
        e.stopPropagation();

        onLeave && onLeave();
      }}
    >
      {user && (
        <>
          <DropdownLink href={`/${locale}/profile`} label={dictionary.myProfile} />

          {user.role === RoleName.Admin && (
            <>
              <DropdownLink href={`/${locale}/admin`} label={dictionary.admin} />

              <DropdownLink href="https://admin.onepole.no/admin" label={"CMS"} />
            </>
          )}
          <LogoutButton />
        </>
      )}
      {!user && (
        <>
          <DropdownLink href={`/${locale}/auth/login`} label={dictionary.login} />

          <DropdownLink href={`/${locale}/auth/register`} label={dictionary.register} />
        </>
      )}
    </ul>
  );
};

export default UserDropdown;
