"use client";

import Script from "next/script";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

declare global {
  interface Window {
    _hsq: any[];
    dataLayer: any[];
  }
}

const HubspotTracker = () => {
  const pathName = usePathname();

  useEffect(() => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function gtag(...args: any[]) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    gtag("consent", "default", {
      analytics_storage: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      region: [
        "AT",
        "BE",
        "BG",
        "HR",
        "CY",
        "CZ",
        "DK",
        "EE",
        "FI",
        "FR",
        "DE",
        "GR",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LI",
        "LT",
        "LU",
        "MT",
        "NL",
        "NO",
        "PL",
        "PT",
        "RO",
        "SK",
        "SI",
        "ES",
        "SE",
        "UK",
        "CH"
      ]
    });

    window._hsq = window._hsq || [];
    window._hsq.push([
      "addPrivacyConsentListener",
      function (consent: any) {
        const hasAnalyticsConsent =
          consent && (consent.allowed || (consent.categories && consent.categories.analytics));
        const hasAdsConsent = consent && (consent.allowed || (consent.categories && consent.categories.advertisement));

        gtag("consent", "update", {
          ad_storage: hasAdsConsent ? "granted" : "denied",
          analytics_storage: hasAnalyticsConsent ? "granted" : "denied",
          ad_user_data: hasAdsConsent ? "granted" : "denied",
          ad_personalization: hasAdsConsent ? "granted" : "denied"
        });
      }
    ]);
    window._hsq.push(["setPath", pathName]);
    window._hsq.push(["trackPageView"]);
  }, [pathName]);

  return (
    <Script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      strategy="afterInteractive"
      src="//js-eu1.hs-scripts.com/143290172.js"
    ></Script>
  );
};

export default HubspotTracker;
