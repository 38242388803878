"use client";

import styles from "@components/Navigation/Navbar/Navbar.module.scss";
import Link from "next/link";
import { RefObject, useContext } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { DictionaryType } from "@utils/Localization/getDictionary";
import { NavContext } from "@components/Navigation/NavProvider";
import { CaretLeft } from "@phosphor-icons/react";

const SubPage = ({
  contentRef,
  useBackButton
}: {
  contentRef?: RefObject<HTMLUListElement>;
  useBackButton?: boolean;
}) => {
  const { locale, translate } = useContext(LocalizationContext);
  const { isSubpageOpen, subpageLinks, handleCloseSubpage, getDropdownWidth } = useContext(NavContext);

  return (
    <div
      className={isSubpageOpen ? styles.subPageDropdownOpen : styles.subPageDropdown}
      onMouseLeave={() => handleCloseSubpage}
      onClick={() => handleCloseSubpage()}
      style={{
        left: contentRef?.current ? `${getDropdownWidth(contentRef.current)}px` : ""
      }}
    >
      {useBackButton && (
        <CaretLeft className={styles.caret} weight="thin" size={24} onClick={() => handleCloseSubpage()} />
      )}

      <ul className={styles.subPageLinks}>
        {subpageLinks &&
          subpageLinks.map((subpageLink, index) => (
            <li key={index} className={`${styles.navItem} ${styles.hover} `} onClick={() => handleCloseSubpage()}>
              <Link href={`/${locale}/${subpageLink.link}`} passHref className="capitalize">
                {translate(subpageLink.name as keyof DictionaryType)}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubPage;
