"use client";

import styles from "./Footer.module.scss";
import Link from "next/link";
import NewsletterFooter from "@components/Navigation/Footer/NewsletterFooter";
import { useContext } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { companyInfo } from "@constants/companyInfo";
import { Locale } from "@config/i18n-config";
import OnepoleLogo from "@components/Icons/OnepoleLogo";
import { UserContext } from "@components/Auth/UserProvider";
import { pages } from "@components/Navigation/Navbar/Navbar";
import { DictionaryType } from "@utils/Localization/getDictionary";
import { InstagramLogo, LinkedinLogo, FacebookLogo } from "@phosphor-icons/react";

const Footer = ({ locale }: { locale: Locale }) => {
  const { dictionary, translate } = useContext(LocalizationContext);
  // TODO MAKE THIS SERVER SIDE, MOVE THE USER BUTTONS TO A COMPONENT

  const { user } = useContext(UserContext);

  return (
    <footer className={styles.footer}>
      <section className={`content ${styles.content}`}>
        <NewsletterFooter />

        <div>
          <h6>{dictionary.linkTitle}</h6>
          <ul>
            {pages
              .filter((page) => page.show) // Only render pages that have show: true
              .map((page) => (
                <li key={page.name}>
                  <Link href={`/${locale}/${page.link}`}>{translate(page.name as keyof DictionaryType)}</Link>
                </li>
              ))}
            <li>
              <Link href={`/${locale}/policy`}>{dictionary.policy}</Link>
            </li>
          </ul>
        </div>

        <div>
          <h6>{dictionary.partners}</h6>
          {!user && (
            <ul>
              <li>
                <Link href={`/${locale}/auth/register`}>{dictionary.becomeAPartner}</Link>
              </li>
              <li>
                <Link href={`/${locale}/auth/login`}>{dictionary.login}</Link>
              </li>
            </ul>
          )}
          {user && (
            <ul>
              <li>
                <Link href={`/${locale}/profile`}>{dictionary.myProfile}</Link>
              </li>
            </ul>
          )}
        </div>

        <div>
          <h6>{dictionary.contact}</h6>

          <a href={"tel:" + companyInfo.support.phone}>
            <p>{companyInfo.support.phone}</p>
          </a>

          <a href={"mailto:" + companyInfo.support.email}>{companyInfo.support.email}</a>

          <a
            className={styles.address}
            href={`https://www.google.com/maps?q=${companyInfo.address}+${companyInfo.zip}+${companyInfo.city}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <p>{companyInfo.address}</p>
            <p>
              {companyInfo.zip} {companyInfo.city}
            </p>
            <p>{companyInfo.country}</p>
          </a>
        </div>

        <div className={styles.some}>
          <h6> {dictionary.followUs}</h6>
          <div className={styles.someIcons}>
            <a
              title="LinkedIn"
              href={companyInfo.links.linkedin}
              target="_blank"
              rel="noreferrer noopener"
              className="flex "
            >
              <LinkedinLogo size={46} weight="thin" />
            </a>
            <a
              title="Instagram"
              href={companyInfo.links.instagram}
              target="_blank"
              rel="noreferrer noopener"
              className="flex "
            >
              <InstagramLogo size={46} weight="thin" />
            </a>
            <a
              title="Facebook"
              href={companyInfo.links.facebook}
              target="_blank"
              rel="noreferrer noopener"
              className="flex "
            >
              <FacebookLogo size={46} weight="thin" />
            </a>
          </div>
          <div className={styles.logo}>
            <OnepoleLogo locale={locale} />
            <a href="https://qlight.no/" target="_blank">
              <span>by Q-Light</span>
            </a>
          </div>
        </div>

        {/*// Display block on mobile*/}
        <div className={styles.logoMobile}>
          <OnepoleLogo locale={locale} />
          <a href="https://qlight.no/" target="_blank">
            <span>by Q-Light</span>
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
