import Link from "next/link";
import { LocaleCode } from "@utils/Country/countryEnums";

const OnepoleLogo = ({
  locale,
  href,
  className,
  svg
}: {
  locale: LocaleCode;
  href?: string;
  className?: string;
  svg?: boolean;
}) => {
  if (svg) {
    return (
      <Link href={href || `/${locale}`} className={className} passHref>
        <svg xmlns="http://www.w3.org/2000/svg" width="163.577" height="25.696" viewBox="0 0 163.577 25.696">
          <g id="ONEPOLE_Logo_Gray_Simple" data-name="ONEPOLE Logo Gray Simple" transform="translate(81.789 12.848)">
            <g id="ONEPOLE_Logo_Green" data-name="ONEPOLE Logo Green" transform="translate(-81.789 -12.848)">
              <g id="Group_4908" data-name="Group 4908">
                <g id="Group_4907" data-name="Group 4907">
                  <path
                    id="Path_11415"
                    data-name="Path 11415"
                    d={
                      "M13,0c7.511,0,13,5.564,13,12.692a12.727,12.727,0,0,1-13,13,12.727," +
                      "12.727,0,0,1-13-13C0,5.564,5.494,0,13,0m0,20.133c3.721,0,6.711-3.2,6." +
                      "711-7.441A6.838,6.838,0,0,0,13,5.564a6.838,6.838,0,0,0-6.711,7.127c0,4.2" +
                      "42,2.99,7.441,6.711,7.441"
                    }
                  />
                  <path
                    id="Path_11416"
                    data-name="Path 11416"
                    d={
                      "M363.1,5.037h6.155l6.885,11.613a44.381,44.381,0,0,1,2.085,4.242h.07s-.277-2.677-." +
                      "277-4.242V5.037H384.1V29.9h-6.12l-6.92-11.578a44.387,44.387,0,0,1-2.086-4.242h-.069s." +
                      "278,2.677.278,4.242V29.9H363.1Z"
                    }
                    transform="translate(-332.95 -4.619)"
                  />
                  <path
                    id="Path_11417"
                    data-name="Path 11417"
                    d="M686.41,5.037h15.577v5.216h-9.492v4.519h7.58v5.216h-7.58v4.693h9.979V29.9H686.41Z"
                    transform="translate(-629.414 -4.619)"
                  />
                  <path
                    id="Path_11418"
                    data-name="Path 11418"
                    d={
                      "M932.669,5.035H943c4.832,0,8.1,3.546,8.1,8.553s-3.269,8.623-8.1,8.623h" +
                      "-4.242V29.9h-6.085ZM941.745,17c2.12,0,3.164-1.426,3.164-3.408s-1.044-3." +
                      "337-3.061-3.337h-3.094V17Z"
                    }
                    transform="translate(-855.226 -4.617)"
                  />
                  <path
                    id="Path_11419"
                    data-name="Path 11419"
                    d={
                      "M1190.674,0c7.511,0,13,5.564,13,12.692a13,13,0,1,1-26.008,0c0-7.127,5.494-12.692,13-12.6" +
                      "92m0,20.133c3.721,0,6.711-3.2,6.711-7.441a6.723,6.723,0,1,0-13.422,0c0,4.242,2.99,7.441,6." +
                      "711,7.441"
                    }
                    transform="translate(-1079.883)"
                  />
                  <path
                    id="Path_11420"
                    data-name="Path 11420"
                    d="M1540.76,5.037h6.085V24.682H1557V29.9H1540.76Z"
                    transform="translate(-1412.824 -4.619)"
                  />
                  <path
                    id="Path_11421"
                    data-name="Path 11421"
                    d="M1776.541,5.037h15.577v5.216h-9.492v4.519h7.58v5.216h-7.58v4.693h9.978V29.9h-16.063Z"
                    transform="translate(-1629.027 -4.619)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Link>
    );
  }

  return (
    <Link href={`/${locale}`} className={className} passHref>
      <img src="/images/icons/onepole_logo.svg" alt="onepole main logo" />
    </Link>
  );
};

export default OnepoleLogo;
