"use client";

import React, { useContext } from "react";
import { UserContext } from "@components/Auth/UserProvider";
import { useRouter } from "next/navigation";
import * as Sentry from "@sentry/nextjs";
import DropdownLink from "@components/Navigation/Navbar/DropdownBox/DropdownLink";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { CartContext } from "@utils/Cart/cartProvider";

export const LogoutButton = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { removeRetailer } = useContext(CartContext);
  const { logout } = useContext(UserContext);
  const router = useRouter();

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      await logout();
      removeRetailer();
      return router.replace(`/`);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <DropdownLink onClick={(e) => handleLogout(e)} href="/logout" label={dictionary.logout} dark prefetch={false} />
  );
};
