"use client";

import styles from "./SearchResults.module.scss";
import Products from "@components/Products/Products";
import React, { useContext, useEffect, useRef } from "react";
import ScrollBar, { ScrollbarPosition } from "@components/Common/ScrollBar/ScrollBar";
import { SearchContext } from "@components/Navigation/Search/SearchProvider";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { createPortal } from "react-dom";
import SearchInput from "@components/Navigation/Search/SearchInput";
import { usePathname } from "next/navigation";

const SearchResult = () => {
  const { dictionary, locale } = useContext(LocalizationContext);
  const { isOpen, isContainerOpen, filteredProducts, setIsOpen } = useContext(SearchContext);
  const pathname = usePathname();

  const attachToRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [pathname]);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className={isContainerOpen ? styles.containerOpen : styles.container}>
      <SearchInput />

      <div className={styles.resultsContainer}>
        <div ref={attachToRef} className={styles.results}>
          <ScrollBar
            attachToRef={attachToRef}
            scrollRef={scrollRef}
            options={{
              show: filteredProducts.length > 0,
              position: ScrollbarPosition.RIGHT,
              offset: "-2%"
            }}
          />

          <div ref={scrollRef} className={styles.resultsScroll}>
            {filteredProducts.length > 0 && <Products locale={locale} products={filteredProducts} />}

            {filteredProducts.length === 0 && (
              <div className={styles.searchNoResult}>
                <h2>{dictionary.noSearchResults}...</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default SearchResult;
