"use client";

import React, { useEffect, useState } from "react";

const AnalyticsBody = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-P4XKR6KB"
      height="0"
      width="0"
      style={{
        display: "none",
        visibility: "hidden"
      }}
    ></iframe>
  );
};

export default AnalyticsBody;
