import styles from "@components/Navigation/Search/Search.module.scss";
import { useContext } from "react";
import { SearchContext } from "@components/Navigation/Search/SearchProvider";
import { MagnifyingGlass } from "@phosphor-icons/react";

const SearchButton = () => {
  const { setIsOpen, setIsContainerOpen } = useContext(SearchContext);

  const handleClick = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsContainerOpen(true);
    }, 10);
  };

  return <MagnifyingGlass onClick={() => handleClick()} className={styles.icon} size={24} weight="light" />;
};

export default SearchButton;
