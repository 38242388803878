import Image from "next/image";
import { StrapiFile } from "@constants/interfaces";
import { ImageSize } from "@constants/enums";
import { CSSProperties } from "react";

export enum ImageFit {
  contain = "contain",
  cover = "cover",
  fill = "fill",
  none = "none",
  scaleDown = "scale-down"
}

interface ProductProps {
  image: StrapiFile;
  alt?: string;
  format?: ImageSize;
  height?: number | undefined;
  width?: number | undefined;
  priority?: boolean;
  quality?: number;
  fill?: boolean;
  placeholder?: {
    placeholder: "blur" | "empty" | undefined;
    blurDataURL: string;
  };
  className?: string;
  styles?: CSSProperties;
  onClick?: () => void;
}

const ProductImage = ({
  image,
  alt = "",
  format,
  height,
  width,
  priority,
  quality,
  fill = false,
  placeholder,
  className,
  styles,
  onClick
}: ProductProps) => {
  // @ts-ignore
  if (image.attributes) {
    // @ts-ignore
    image = image.attributes;
  }

  if (!format || !image.formats || !image.formats[format]) {
    return (
      <Image
        src={image.url}
        alt={alt}
        width={width || undefined}
        height={height || undefined}
        priority={priority}
        quality={quality}
        fill={fill}
        placeholder={placeholder?.placeholder}
        blurDataURL={placeholder?.blurDataURL}
        className={className}
        style={{
          ...styles
        }}
        onClick={onClick && onClick}
      />
    );
  }

  return (
    <Image
      src={image.formats[format].url}
      alt={alt}
      width={width || undefined}
      height={height || undefined}
      priority={priority}
      quality={quality}
      fill={fill}
      placeholder={placeholder?.placeholder}
      blurDataURL={placeholder?.blurDataURL}
      className={className}
      style={{
        ...styles
      }}
      onClick={onClick && onClick}
    />
  );
};

export default ProductImage;
