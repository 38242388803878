import React from "react";
import Link from "next/link";

import styles from "@components/Navigation/Navbar/Navbar.module.scss";
import Chevron from "@components/Navigation/Navbar/LanguageSwitch/Chevron";

interface DropdownProps {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  isOpen?: boolean;
  useChevron?: boolean;
  href: string;
  label: string;
  dark?: boolean;
  prefetch?: boolean;
}

const DropdownLink = ({ isOpen, useChevron, onClick, href, label, dark = false, prefetch = true }: DropdownProps) => {
  return (
    <Link
      prefetch={prefetch}
      href={href}
      className={`${styles.navItem}  ${dark ? styles.dropDownLinkDark : styles.dropDownLink}`}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      <li className={styles.li}>{label}</li>

      {useChevron && <Chevron show={isOpen || false} />}
    </Link>
  );
};

export default DropdownLink;
