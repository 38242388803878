import styles from "./Products.module.scss";

import React from "react";
import ProductCard from "@components/Products/ProductCard";
import CartItemProvider from "@components/Cart/CartItem/CartItemProvider";
import { ProductData } from "@utils/Product/interfaces";
import { LocaleCode } from "@utils/Country/countryEnums";

interface ProductProps {
  locale: LocaleCode;
  category?: {
    name: string;
    link: string;
  };
  products: ProductData[];
}

const Products = ({ locale, products }: ProductProps) => {
  return (
    <div className={styles.productsContainer}>
      <div className={styles.productGrid}>
        {products.map((productData, index) => (
          <CartItemProvider productData={productData} key={index}>
            <ProductCard locale={locale} key={index} productData={productData} />
          </CartItemProvider>
        ))}
      </div>
    </div>
  );
};

export default Products;
