import ProductImage from "@components/Common/ProductImage";
import { ImageSize } from "@constants/enums";
import Link from "next/link";
import React from "react";
import { ProductData } from "@utils/Product/interfaces";
import { LocaleCode } from "@utils/Country/countryEnums";
import { ImageSquare } from "@phosphor-icons/react/dist/ssr";

const ProductCardImage = ({ locale, productData }: { locale: LocaleCode; productData: ProductData }) => {
  if (!productData) {
    return null;
  }

  const getImage = () => {
    // find first image in productData.productImages where mime is not video
    const image = productData.productImages?.find((image) => !image.mime?.includes("video"));

    if (image) {
      return (
        <ProductImage
          image={image}
          placeholder={
            image.formats?.thumbnail?.url
              ? {
                  placeholder: "blur",
                  blurDataURL: image.formats.thumbnail.url || ""
                }
              : undefined
          }
          alt={productData.productName}
          format={ImageSize.small}
          quality={100}
          width={450}
          height={450}
          styles={{
            filter: "brightness(130%)",
            height: "100%",
            width: "100%"
          }}
        />
      );
    } else {
      return <ImageSquare width={"100%"} height={"100%"} color="#9aceba" weight="light" />;
    }
  };

  return (
    <Link
      href={`/${locale}/products/${productData.product_category?.link}/` + `${productData.productNumber}`.toLowerCase()}
    >
      <div>{getImage()}</div>
    </Link>
  );
};

export default ProductCardImage;
