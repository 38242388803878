import { useEffect, useState } from "react";

export enum SwipeDirection {
  Down = "Down",
  DownRight = "DownRight",
  Right = "Right",
  UpRight = "UpRight",
  Up = "Up",
  UpLeft = "UpLeft",
  Left = "Left",
  DownLeft = "DownLeft",
  None = "None"
}

const useDetectInput = () => {
  const [isHoldingShift, setIsHoldingShift] = useState(false);
  const [isHoldingCtrl, setIsHoldingCtrl] = useState(false);
  const [isHoldingAlt, setIsHoldingAlt] = useState(false);
  const [isZooming, setIsZooming] = useState(false);
  // const [isSwiping, setIsSwiping] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection | null>(null);
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  let touchStartDistance = 0;
  let touchStartPosition = { x: 0, y: 0 };

  const handleKeyDown = (event: KeyboardEvent) => {
    setIsHoldingShift(event.shiftKey);
    setIsHoldingCtrl(event.ctrlKey);
    setIsHoldingAlt(event.altKey);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (!event.shiftKey) {
      setIsHoldingShift(false);
    }
    if (!event.ctrlKey) {
      setIsHoldingCtrl(false);
    }
    if (!event.altKey) {
      setIsHoldingAlt(false);
    }
  };

  const getSwipeDirection = (dx: number, dy: number): SwipeDirection | null => {
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

    if (angle >= -22.5 && angle < 22.5) return SwipeDirection.Right;
    if (angle >= 22.5 && angle < 67.5) return SwipeDirection.DownRight;
    if (angle >= 67.5 && angle < 112.5) return SwipeDirection.Down;
    if (angle >= 112.5 && angle < 157.5) return SwipeDirection.DownLeft;
    if ((angle >= 157.5 && angle <= 180) || (angle >= -180 && angle < -157.5)) return SwipeDirection.Left;
    if (angle >= -157.5 && angle < -112.5) return SwipeDirection.UpLeft;
    if (angle >= -112.5 && angle < -67.5) return SwipeDirection.Up;
    if (angle >= -67.5 && angle < -22.5) return SwipeDirection.UpRight;

    return SwipeDirection.None;
  };

  const handleTouchStart = (event: TouchEvent) => {
    if (event.touches.length === 2) {
      const dx = event.touches[0].clientX - event.touches[1].clientX;
      const dy = event.touches[0].clientY - event.touches[1].clientY;
      touchStartDistance = Math.sqrt(dx * dx + dy * dy);
    }

    if (event.touches.length === 1) {
      touchStartPosition = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY
      };
    }
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (event.touches.length === 2 && touchStartDistance !== 0) {
      const dx = event.touches[0].clientX - event.touches[1].clientX;
      const dy = event.touches[0].clientY - event.touches[1].clientY;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (Math.abs(distance - touchStartDistance) > 10) {
        setIsZooming(true);
      }
    }
  };

  const handleTouchEnd = (event: TouchEvent) => {
    if (event.changedTouches.length === 1) {
      const dx = event.changedTouches[0].clientX - touchStartPosition.x;
      const dy = event.changedTouches[0].clientY - touchStartPosition.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance > 50) {
        // You can adjust this threshold
        setSwipeDirection(getSwipeDirection(dx, dy));
      } else {
        setSwipeDirection(null);
      }
    }

    setIsZooming(false);
    touchStartDistance = 0;
  };

  const handleScroll = () => {
    setScrollPosition({
      x: window.pageXOffset || document.documentElement.scrollLeft,
      y: window.pageYOffset || document.documentElement.scrollTop
    });
  };

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    document.body.addEventListener("keyup", handleKeyUp);
    document.body.addEventListener("touchstart", handleTouchStart);
    document.body.addEventListener("touchmove", handleTouchMove);
    document.body.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
      document.body.removeEventListener("keyup", handleKeyUp);
      document.body.removeEventListener("touchstart", handleTouchStart);
      document.body.removeEventListener("touchmove", handleTouchMove);
      document.body.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return { isHoldingShift, isHoldingCtrl, isHoldingAlt, isZooming, swipeDirection, scrollPosition };
};

export default useDetectInput;
