"use client";

import styles from "@components/Navigation/Navbar/UserNavButton.module.scss";
import UserDropdown from "@components/Navigation/Navbar/UserDropdown";
import React, { useContext } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import DropdownLink from "@components/Navigation/Navbar/DropdownBox/DropdownLink";
import Link from "next/link";
import { User } from "@phosphor-icons/react";

export const UserNavbar = ({ isMobile }: { isMobile?: boolean }) => {
  const { dictionary, locale } = useContext(LocalizationContext);
  // const page = "";

  const [isOpen, setIsOpen] = React.useState(false);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={styles.dropDownContainer}
      onMouseOver={(e) => {
        if (e.currentTarget === e.target) {
          openMenu();
        }
      }}
      onMouseLeave={() => closeMenu()}
      onClick={() => toggleMenu()}
    >
      {isMobile ? (
        <DropdownLink href={`#`} label={dictionary.user} isOpen={isOpen} useChevron />
      ) : (
        <Link href={`/${locale}/auth`}>
          <User size={24} weight="light" />
        </Link>
      )}

      <UserDropdown isOpen={isOpen} onLeave={() => closeMenu()} />
    </div>
  );
};

export default UserNavbar;
