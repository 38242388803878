import styles from "./ProductCard.module.scss";
import React from "react";
import ProductCardImage from "@components/Products/ProductCardImage";
import Link from "next/link";
import ProductCardPrice from "@components/Products/ProductCardPrice";
import SubmitOrAddToCartButton from "@components/Products/SubmitOrAddToCartButton";
import { ProductData } from "@utils/Product/interfaces";
import { LocaleCode } from "@utils/Country/countryEnums";

const ProductCard = ({ locale, productData }: { locale: LocaleCode; productData: ProductData }) => {
  return (
    <div key={productData.productNumber} className={styles.productCard}>
      <div className={styles.productCardBody}>
        <ProductCardImage locale={locale} productData={productData} />
      </div>
      <div className={styles.productCardInfo}>
        <Link
          href={
            `/${locale}/products/${productData.product_category?.link}/` + `${productData.productNumber}`.toLowerCase()
          }
        >
          <h5 className={styles.number}>
            {productData.productNumber}
            {locale === LocaleCode.Norway && productData.elNumber && " / "}
            {locale === LocaleCode.Norway && productData.elNumber}
          </h5>
          <h4
            className={styles.title}
            title={(productData.productNameShort && productData.productNameShort) || productData.productName}
          >
            {(productData.productNameShort && productData.productNameShort) || productData.productName}
          </h4>
          <p className={styles.productCardInfoDescription}>{productData.description}</p>
        </Link>
        <div className={styles.productCardFooter}>
          <SubmitOrAddToCartButton />
          <div>
            <ProductCardPrice productData={productData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
