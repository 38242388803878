import { PriceInterface, SpecificationInterface, StrapiFile } from "@constants/interfaces";
import { LocaleCode } from "@utils/Country/countryEnums";

export interface ProductData {
  id: number;
  erpId: number;
  configurable: boolean;
  enableColumnPricing: boolean;
  productName: string;
  productNameShort: string;
  productNumber: string;
  elNumber: string;
  gtin: string;
  hsCode: string;
  productImages?: StrapiFile[];
  configuratorImage?: StrapiFile;
  description?: string;
  publishedAt?: string | null;
  price?: PriceInterface[];
  specification?: SpecificationInterface[];
  product_category?: ProductCategoryData;
  related_products?: ProductData[];
  files?: StrapiFile[];
  object?: StrapiFile;
  locale: string;
  localization?: any; // TODO make interface
  createdAt: Date;
  updatedAt: Date;
}

export type ProductAttributesType = keyof ProductData;

export interface ProductCategoryData {
  id: number;
  link: string;
  slug: string;
  name: string;
  locale: LocaleCode;
  visible: boolean;
  addon: boolean;
  createdAt: string;
  updatedAt: string;
  order: number;
  image?: StrapiFile;
}

export type ProductCategoryAttributesType = keyof ProductCategoryData;

export enum ProductCategory {
  "Pedestal" = "pedestal",
  "MountingBracket" = "mounting-bracket",
  "BracketHolder" = "bracket-holder",
  "LightingKit" = "lighting-kit",
  "Roof" = "roof",
  "TopLantern" = "top-lantern",
  "Foundation" = "foundation",
  "Accessory" = "accessory",
  "SpareParts" = "spare-parts",
  "Foundation accessories" = "foundation-accessories"
}
