import styles from "./Chevron.module.scss";
import React from "react";
import { CaretDown, CaretUp } from "@phosphor-icons/react/dist/ssr";

const Chevron = ({ show }: { show: boolean }) => {
  // TODO - add animation
  return (
    <div className={styles.chevron}>
      {show ? <CaretUp size={16} weight="light" /> : <CaretDown size={16} weight="light" />}
    </div>
  );
};

export default Chevron;
